<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12 md:col-6">
            <app-basic-input label="Name"
                [placeholder]="'Enter Name'"
                [control]="formGroup?.controls?.name"
                [viewMode]="fieldViewMode"></app-basic-input>
        </div> -->
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Filter Version'"
        [placeholder]="'Filter Version'"
        [name]="'filterVersion'"
        [showButtons]="true"
        [control]="formGroup?.controls?.filterVersion"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
      ></app-number-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Collection Date'"
        [placeholder]="'2022-11-22'"
        [name]="'collectionDate'"
        [showIcon]="false"
        [control]="formGroup?.controls?.collectionDate"
        [viewMode]="fieldViewMode"
      ></app-date-input>
    </div>
    <!-- <div class="col-12">
      <app-dynamic-object-viewer [label]="'Result'" [data]="data?.aggregateDataOptions"> </app-dynamic-object-viewer>
    </div> -->
    @if (data?.aggregateDataOptions) {
      <div class="col-12">
        <app-statistics-table [options]="statisticsTableOptions"> </app-statistics-table>
      </div>
    }
    <!-- <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
