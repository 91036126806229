import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, fixAggregateId, GeneratedStatisticDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-generated-statistic-item-form',
  templateUrl: './generated-statistic-item-form.component.html',
  styleUrl: './generated-statistic-item-form.component.scss',
})
export class GeneratedStatisticItemFormComponent extends BaseForm<GeneratedStatisticDto> implements OnInit {
  statisticsTableOptions = null;
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'GENERATED_STATISTIC');
  }
  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    this.statisticsTableOptions = data?.aggregateDataOptions
      ? {
          payload: this.data?.aggregateDataOptions?.aggregationBody,
          response: fixAggregateId(
            this.data?.aggregateDataOptions?.aggregationBody?.groupByFields?.map((x) => x.fieldName),
            this.data?.aggregateDataOptions?.response
          ),
        }
      : null;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // name: new FormControl(null),
      result: new FormControl(null),
      filterVersion: new FormControl(null),
      collectionDate: new FormControl(null),
      description: new FormControl(null),
    });
  }
}
